<template>
  <div
    style="justify-self: center;"
    dir="rtl"
    class="countDown text-no-wrap flex-wrap"
  >
    <v-card
      color="#9155FD"
      class="justify-center align-center pa-5 mx-auto"
      width="190px"
    >
      <div
        class="text-6xl justify-center content-center white--text"
        dir="ltr"
      >
        <v-row
          align="center"
          justify="center"
        >
          <div
            class="days mx-3"
          >
            {{ displayDays }}
          </div>
          <span class="leading-snug">:</span>
          <div class="hours mx-3">
            {{ displayHours }}
          </div>
          <span class="leading-snug">:</span>
          <div class="minutes mx-3">
            {{ displayMinutes }}
          </div>
        </v-row>
      </div>
    </v-card>
    <v-card
      outlined
      color="transparent"
      class="justify-center align-center mx-auto mt-3"
      width="200px"
    >
      <v-row
        align="center"
        justify="center"
      >
        <div class="label text-sm bottom-0 absolute minutes mx-n2">
          minutes
        </div>

        <div class="label text-sm bottom-0 absolute hours mx-6">
          hours
        </div>
        <div class="label text-sm bottom-0 absolute">
          days
        </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ['eventDateProps'],
  data: () => ({
    displayDays: 0,
    displayHours: 0,
    displayMinutes: 0,
    displaySeconds: 0,
    seconds: 1000, // Moved to data
    minutes: 60000, // 1000 * 60
    hours: 3600000, // 1000 * 60 * 60
    days: 86400000, // 1000 * 60 * 60 * 24
  }),
  watch: {
    eventDateProps: {
      immediate: true,
      deep: true,
      handler() {
        this.showRemaining()
      },
    },
  },
  methods: {
    formatNum: num => (num < 10 ? `0${num}` : num),
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date()
        const eventDate = new Date(this.eventDateProps ? this.eventDateProps : 0)
        const end = new Date(eventDate)

        const distance = end.getTime() - now.getTime()

        if (distance < 0) {
          clearInterval(timer)
          this.displayDays = `0${0}`
          this.displayHours = `0${0}`
          this.displayMinutes = `0${0}`
          this.displaySeconds = `0${0}`

          return
        }

        const days = Math.floor(distance / this.days)
        const hours = Math.floor((distance % this.days) / this.hours)
        const minutes = Math.floor((distance % this.hours) / this.minutes)
        const seconds = Math.floor((distance % this.minutes) / this.seconds)
        this.displayMinutes = this.formatNum(minutes)
        this.displayHours = this.formatNum(hours)
        this.displayDays = this.formatNum(days)
        this.displaySeconds = this.formatNum(seconds)
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
.days,
.hours,
.minutes {
  position: relative;
}
.countDown {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 23px;
}
</style>
